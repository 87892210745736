// Others
import { createRouter, createWebHistory } from "vue-router"
// Component
import { rootRoutes } from "@/router/root/rootRoutes"
import { authRoutes } from "@/router/auth/authRoutes"
import { userRoutes } from "@/router/user/userRoutes"
import { tributaryRoutes } from "@/router/tributary/tributaryRoutes"
import { adminRoutes } from "@/router/admin/adminRoutes"
import { publicRoutes } from "@/router/public/publicRoutes"
import { verifyRoutes } from "@/router/verify/verifyRoutes"
import { treasuryRoutes } from "@/router/treasury/treasuryRoutes"
import { portfolioRoutes } from "@/router/portfolio/portfolioRoutes"
import { refRoutes } from "@/router/ref/refRoutes"
import { constancyRoutes } from "@/router/constancy/constancyRoutes"
import { employeeRoutes } from "@/router/employee/employeeRoutes"
import { formRoutes } from "./form/formRoutes"
import { catastroRoutes } from "./catastro/catastroRoutes"
import { supportRoutes } from "./support/supportRoutes"
import { adsRoutes } from "./ads/adsRoutes"
// Store
import store from "@/store"


//Routes
const routes = [
    ...authRoutes,
    ...publicRoutes,
    {
        path: "/:role",
        component: () => import("@/views/ViewSystem.vue"),
        children: [
            ...rootRoutes,
            ...userRoutes,
            ...adminRoutes,
            ...verifyRoutes,
            ...treasuryRoutes,
            ...portfolioRoutes,
            ...refRoutes,
            ...constancyRoutes,
            ...employeeRoutes,
            ...tributaryRoutes,
            ...formRoutes,
            ...catastroRoutes,
            ...supportRoutes,
            ...adsRoutes
        ]
    },
    {
        path: "/cuenta",
        name: "Cuenta",
        component: () => import("@/views/user/Profile/ViewProfile.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Cuenta",
            title: "Cuenta",
        }
    },
    {
        path: "/opciones-usuario",
        name: "Opciones-usuario",
        component: () => import("@/views/auth/ViewSystemOption.vue"),
        meta: {
            requiresAuth: true,
            title: "Opciones de usuario",
        }
    },
    {
        path:"/solicitud-verificacion",
        name:"Solicitud-verificacion",
        component:()=>import("@/views/user/ViewVerifyAccount.vue"),
        meta:{
            requiresAuth: true,
            title: "Solicitud de verificación",
        }
    },
    {
        path: "/nueva-contrasena",
        name: "Nueva-contrasena",
        component: () => import("@/views/auth/ViewCreateNewPassword.vue"),
        meta: {
            requiresAuth: true,
            title: "Nueva contraseña",
        }
    },
    {
        path: "/:pathMatch(.*)*",
        name: "Notfound",
        component: () => import("@/views/ViewNotFound.vue")
    }
]

//Initialization
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    }
})

//Guards
router.beforeEach(async (to, from, next) => {

    const token = localStorage.getItem('token');
    let hasPendingPassword = store.state.auth.user?.hasPendingPassword;
    const dateLoginRaw = localStorage.getItem('dateLogin');
    const dateLogin = dateLoginRaw ? JSON.parse(dateLoginRaw) : null;

    // Si el usuario aún no está cargado, espera a que se cargue.
    if (token && !store.state.auth.user) {
        console.log('user not loaded, waiting for it to load');
        try {
            await store.dispatch('validateUser'); // Asegúrate de que esta acción cargue el usuario
            hasPendingPassword = store.state.auth.user?.hasPendingPassword;
        } catch (error) {
            console.error('Error al validar usuario:', error);
        }
    }

    // Verificar si la fecha de inicio de sesión es válida y si el token ha expirado
    if (dateLogin && new Date(dateLogin) < new Date() && token) {
        localStorage.removeItem('token');
        localStorage.removeItem('dateLogin');
    }

    // Verificar si la ruta requiere autenticación basándose en la propiedad meta "requiresAuth"
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    // Redirigir al usuario a la página principal ("/") si la ruta requiere autenticación y no hay token
    if (requiresAuth && !token) {
        return next("/");
    }

    // Si la ruta es "/" y el usuario está autenticado, redirigir a "/opciones-usuario"
    if (to.path === "/" && token) {
        return next("/opciones-usuario");
    }

    // Redirigir a "/nueva-contrasena" si hay una sesion activa y el usuario tiene una contraseña pendiente
    if (token && hasPendingPassword != false && to.path !== "/nueva-contrasena") {
        return next("/nueva-contrasena");
    }

    // Establecer el título de la página si está definido en la meta de la ruta
    if (to.meta.title) {
        document.title = to.meta.title;
    }


    // Proceder a la siguiente ruta
    next();
});



export default router
