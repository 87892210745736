import HTTP from "../../services/axios"
import store from "@/store/index"
// import { decryptPass } from "@/utils/data"
import {
  getMenu,
  changeEmail,
  LogInSesion,
  LogoutSesion,
  validateUser,
  createAccount,
  changePassword,
  recoveyPassword,
  validateAccount,
  registerDataAccount,
  notificationAccount,
  notificationStatus,
} from "../../services/paths"
import { reportError } from "@/utils/error-handler"

export default {
  state() {
    return {
      user: null,
      token: null,
      account: null,
      loading: false,
      error: null,
      success: null,
      errorLogin: null,
      errorCreateAccount: null,
      errorRegisterData: null,
      errorRecoveryPassword: null,
      errorUser: null,
      recoveryPassword: null,
      temporaryData: null,
      dateLogin: null,
      createAccount: null,
      validateAccount: null,
      validateAccountPhone: null,
      notificationAccount: null,
      notificationStatus: null,
      actualMenu: 1,
      loadingMenu: false,
      dataMenu: [],
      errorMenu: false,

      showActivationMessage: true,
      permissions: [],
      redirectTo:null
    }
  },

  mutations: {
    refreshMenu(state, id) {
      state.actualMenu = id
    },
    loadingMenu(state) {
      state.errorMenu = false,
      state.loadingMenu = true,
      state.dataMenu = null
    },
    getMenu(state, data) {
      state.errorMenu = false,
      state.loadingMenu = false,
      state.dataMenu = data
    },
    errorMenu(state, data) {
      state.errorMenu = true,
        state.loadingMenu = false,
        state.dataMenu = data
    },
    //Inicializa el loading y se limpian los errores
    initLoading(state) {
      state.loading = true
      state.error = false
      state.errorLogin = false
      state.errorRegisterData = false
      state.errorRegister = false
    },
    //Finaliza el loading
    finishLoading(state) {
      state.loading = false
    },

    //Activa el error y guarda el error del modal login
    errorLogin(state, data) {
      state.error = true
      state.errorLogin = data
    },

    //Activa el error y guarda el error del modal crear cuenta
    errorCreateAccount(state, data) {
      state.error = true
      state.errorCreateAccount = data
    },

    //Activa el error y guarda el error en el la vista registrar datos adicionales
    errorRegisterData(state, data) {
      state.error = true
      state.errorRegisterData = data
    },

    errorUser(state, data) {
      state.errorUser = data
    },

    //Activa el error de cambio de contrasenia
    errorRecoveryPassword(state, data) {
      state.error = true
      state.success = false
      state.errorRecoveryPassword = data
    },

    //Almacena la respuesta a la peticion de validar cuenta por correo en validateAccount
    validateAccount(state, data) {
      state.validateAccount = data
    },

    //Almacena la respuesta a la peticion de validar cuenta por telefono en validateAccountPhone
    validateAccountPhone(state, data) {
      state.validateAccountPhone = data
    },

    //Almacena los datos temporales del usuario en temporaryData
    temporaryData(state, data) {
      state.temporaryData = data
    },

    //Agrega el uid de la cuenta temporal y los datos activo = 1 y tipo = 0
    temporaryDataUid(state, data) {
      state.temporaryData.uid = data
      state.temporaryData.active = 1
      state.temporaryData.type = 0
    },

    //Alamacena los datos del usuario activo en user
    setUser(state, data) {
      state.user = data
    },

    //Alamacena los datos del usuario activo en user
    setToken(state, data) {
      const newData = {
        token: data?.accessToken,
        dateLogin: new Date().setHours(new Date().getHours() + 1) // Guarda la hora en que se obtuvo el token inicial o nuevo más 1 hora de duración.
      }
      state.token = newData
      localStorage.setItem('token', newData?.token)
      localStorage.setItem('dateLogin', JSON.stringify(newData?.dateLogin))
    },

    //Almacena la cuenta temporal en account
    createAccount(state, data) {
      state.account = data
    },

    //cambia estado a recuperar cuenta
    setRecoveryPassword(state) {
      state.success = false
      state.recoveryPassword = null
      state.error = false
    },

    //cambia estado a recuperar cuenta
    recoveryPassword(state, data) {
      state.success = true
      state.recoveryPassword = data
      state.error = false
    },

    //limpia los errores
    clearError(state) {
      state.error = false
      state.errorLogin = false
      state.errorRegister = false
      state.loading = false
    },

    //Borra los datos del usuario cuando se cierra sesion
    clearUser(state) {
      state.user = null,
      state.token = null,
      state.dateLogin = null
    },

    //Limpia los datos de validateAccount
    clearValidateAccount(state) {
      state.validateAccount = null
    },

    //Guardamos la notifiaciones
    MutationNotification(state, data) {
      state.notificationAccount = data
    },

    //Actualizaremos la notifiaciones
    MutationNotificationStatus(state, data) {
      state.notificationStatus = data
    },

    setShowActivationMessage(state, data) {
      state.showActivationMessage = data
    },


    setCurrentPermissions(state, data) {
      state.permissions = data
    },

    setRedirectTo(state,route){
      state.redirectTo = route
    }
  },

  actions: {
    async refreshMenu({ commit }, id) {
      commit("refreshMenu", id)
    },
    async getMenu({ commit }, id) {
      commit("loadingMenu")
      try {
        await HTTP.get(getMenu(id)).then((user) => {
          const { data: { data } } = user
          commit("getMenu", data)
        })
      } catch (errorGlobal) {
        commit("errorMenu", errorGlobal?.response?.data)
      }
    },
    //API de Inicio de Session se utiliza en ComponentLogin
    async LogInSesion({ commit }, body) {
      const resLoginDB = await HTTP.post(LogInSesion(), body)
      commit("setUser", resLoginDB?.data?.data)
      commit("setToken", resLoginDB?.data?.data.token)
    },

    //API de Crearcion de Cuenta Temporal se utiliza en ComponentRegister
    async createAccount({ commit }, body) {
      commit("initLoading")

      await HTTP
        .post(createAccount(), body)
        .then((response) => {
          if (response.status === 200) {
            commit("createAccount", response.data?.data)
          } else {
            commit("errorCreateAccount", response.response.data)
          }
        })
        .catch((err) => {
          commit("errorCreateAccount", err.response.data)
          commit("finishLoading")
        })
      commit("finishLoading")
    },

    //API que Registra los Datos Adicionales del usuario se utiliza en ViewRegister
    async RegisterData({ commit }, body) {
      commit("initLoading")

      try {
        await HTTP.post(registerDataAccount(), body)
          .then((response) => {
            if (response.data?.status === 202) commit("setShowActivationMessage", false)
          })
          .catch((error) => {
            commit("errorRegisterData", error?.response?.message)
          })
      } catch (errorGlobal) {
        commit("errorRegisterData", errorGlobal)
      }
      commit("finishLoading")
    },

    //API que cierra session en google y en el sistema se utiliza en ComponentNavbar
    async signOut({ commit }) {
      try {
        await HTTP.get(LogoutSesion())
        commit("clearUser")
      } catch (error) {
        reportError(error, "auth/signOut", "Error al cerrar sesión")
      }
    },

    //API que Valida la cuenta mediante codigo enviado a Email se utiliza en ViewVerifyCode
    async validateAccount({ commit }, body) {
      const response = await HTTP.post(validateAccount(), body)
      commit("validateAccount", response.data)
      commit("temporaryData", response.data.data[0])
      console.log(response.data)
    },

    //API que inicia el proceso de recuperar Contraseña, se utiliza en ComponentRecoveryPassword
    async recoveyPassword({ commit }, body) {
      commit("initLoading")

      commit("setRecoveryPassword")

      await HTTP
        .post(recoveyPassword(), body)
        .then((response) => {
          if (response.status === 200) {
            commit("recoveryPassword", response.data)
          }
        })
        .catch((err) => {
          console.log("err", err.response.status)
          commit("errorRecoveryPassword", err.response)
          console.log(err.response.data.message)
        })

      commit("finishLoading")
    },

    async changeEmail({ commit }, body) {
      await HTTP.post(changeEmail(), body)
      const response = await HTTP.get(validateUser())

      commit("setUser", response.data.data)
    },

    //API que cambia la Contraseña 
    async changePassword({ commit }, body) {
      await HTTP.post(changePassword(), body)
      const response = await HTTP.get(validateUser())

      commit("setUser", response.data.data)
    },

    // API que cambia la Contraseña sin validar el usuario
    async changePasswordQuietly(_, body) {
      await HTTP.post(changePassword(), body)
    },

    //API que Valida al usuario activo y los permisos con los que cuenta
    async validateUser({ commit }) {
      await HTTP
        .get(validateUser())
        .then((response) => {
          if (response.status === 200) {
            commit("setUser", response.data.data)
          }
        })
        .catch((err) => {
          localStorage.removeItem('token')
          commit("errorUser", err)
          return err
        })
    },

    //Obtenemos notifiaciones
    async notificationAccount({ commit }) {
      await HTTP.get(notificationAccount()).then((response) => {
          if (response.status === 200) {
            commit("MutationNotification", response?.data?.data)
          }
        })
        .catch(() => {
          commit("MutationNotification", null)
        })
    },

    //Actualizamos notifiaciones
    async notificationStatus({ commit }, body) {
      await HTTP
        .post(notificationStatus(), body)
        .then((response) => {
          if (response.status === 200) {
            commit("MutationNotificationStatus", response?.data?.data[0])
          }
        })
        .catch((err) => {
          commit("MutationNotificationStatus", err)
        })
    },

    /*-------------------Funciones--------------------*/

    //Limpia los Datos temporales y errores 
    clearData({ commit }, data) {
      commit("temporaryData", data)
      commit("clearError")
    },

    //Guarda los datos temporales del usuario
    temporaryData({ commit }, data) {
      commit("temporaryData", data)
    },

    //Agrega el valor de uid a los datos temporales cuando es por creacion por metodo de telefono
    temporaryDataUid({ commit }, data) {
      commit("temporaryDataUid", data)
    },

    getRolePermissions({ commit }, role) {
      const currentRole = store.state?.auth?.user?.roles.find((item) => {
        return item.enlaceSistema === role
      })

      commit("setCurrentPermissions", currentRole?.permissions || [])
    }
  },
}
