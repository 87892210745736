const userRoutes = [{
        path: "dashboard",
        name: "Dashboard",
        component: () => import("@/views/admin/ViewDashboard.vue"),
        props: true,
        meta: {
            requiresAuth: true,
            title: "Dashboard",
        }
    },
    {
        path: "historial-pago",
        name: "Historial-pago",
        component: () => import("@/views/user/ViewPaymentHistory.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Historial de pagos",
            title: "Historial de pagos",
        }
    },
    {
        path: "resumen-cuentas",
        name: "Resumen-cuentas",
        component: () => import("@/views/user/AccountStatus/ViewAccount.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Resumen de cuentas",
            title: "Resumen de cuentas",
        }
    }, {
        path: "solicitud-solvencia",
        name: "Solicitud-solvencia",
        component: () => import("@/views/user/SolvencyRequest/ViewSolvency.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Solvencias",
            title: "Solvencias",
        }
    },
    {
        path: "solicitud-planes-pago",
        name: "Solicitud-planes-pago",
        component: () => import("@/views/user/PaymentPlan/ViewTablePlan.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Planes de pago",
            title: "Planes de pago",
        }
    },
    {
        path: "solicitud-declaracion-impuestos/:requestId?",
        name: "Solicitud-declaracion-impuestos",
        component: () => import("@/views/user/TaxDeclarationRequest/ViewRequestTaxDeclaration"),
        meta: {
            requiresAuth: true,
            breadcrumb:"Presentación de Estados Financieros",
            title: "Presentación de Estados Financieros"
        }
    }, 
    {
        path:"declaracion-impuestos-inventario/:requestId?",
        name:"Declaracion-impuestos-inventario",
        component:() => import("@/views/user/TaxDeclarationRequest/ViewRequestInventory.vue"),
        meta:{
            requiresAuth:true,
            breadcrumb:"Presentación de Inventario",
            title:"Presentación de Inventario"
        }
    },
    {
        path: "declaraciones-impuestos",
        name: "Declaraciones-impuestos",
        component: () => import('@/views/user/TaxDeclarationRequest/ViewTaxDeclarations.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: "Declaración de Estados Financieros",
            title: "Declaración de Estados Financieros",
        }
    }

]

export {
    userRoutes
}